import { useTrackEvent } from '@troon/analytics';
import { IconShare } from '@troon/icons/share';
import { IconSocialFacebook, IconSocialLinkedin, IconSocialTwitter } from '@troon/icons/social';
import { Link } from '@troon/ui';
import { createMemo, Show } from 'solid-js';
import { isServer } from 'solid-js/web';

type Props = ShareData;

export function ShareButtons(props: Props) {
	const trackEvent = useTrackEvent();

	const canShare = createMemo(() => {
		return (
			isServer ||
			(typeof window !== 'undefined' &&
				typeof window.navigator.share === 'function' &&
				window.navigator.canShare({ title: props.title, text: props.text, url: props.url }))
		);
	});

	return (
		<ul class="flex gap-3 text-black">
			<Show when={canShare()}>
				<li class="shrink-0">
					<button
						class="flex rounded-full bg-neutral-100 p-1 hover:bg-brand-100 hover:text-brand"
						onClick={(e) => {
							e.preventDefault();
							navigator.share({ url: props.url }).then(() => {
								trackEvent('shareAccessReferral', { shareType: 'native' });
							});
						}}
					>
						<IconShare title="share" class="size-6" />
					</button>
				</li>
			</Show>
			<li class="shrink-0">
				<Link
					class="flex rounded-full bg-neutral-100 p-1 hover:bg-brand-100 hover:text-brand"
					href={`https://www.linkedin.com/shareArticle?url=${props.url}&title=${props.title}`}
					onClick={() => {
						trackEvent('sharePage', { shareType: 'linkedin' });
					}}
				>
					<IconSocialLinkedin title="Email" class="size-6" />
				</Link>
			</li>
			<li class="shrink-0">
				<Link
					class="flex rounded-full bg-neutral-100 p-1 hover:bg-brand-100 hover:text-brand"
					href={`https://www.facebook.com/sharer/sharer.php?u=${props.url}`}
					onClick={() => {
						trackEvent('sharePage', { shareType: 'facebook' });
					}}
				>
					<IconSocialFacebook title="Facebook" class="size-6" />
				</Link>
			</li>
			<li class="shrink-0">
				<Link
					class="flex rounded-full bg-neutral-100 p-1 hover:bg-brand-100 hover:text-brand"
					href={`https://x.com/share?text=${props.title}!%0A%0A${props.url}`}
					onClick={() => {
						trackEvent('sharePage', { shareType: 'twitter' });
					}}
				>
					<IconSocialTwitter title="Twitter / X" class="size-6" />
				</Link>
			</li>
		</ul>
	);
}
